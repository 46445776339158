.sb-drsk__viz-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    will-change: transform;
}

.sb-drsk__viz-container * {
    pointer-events: none !important;
}